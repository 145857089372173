<template>
<v-container fluid>
  <v-tabs v-model="tab" icons-and-text height="36">
    <v-tabs-slider></v-tabs-slider>
    <v-tab href="#tab-1">
      <span>
        <span>日报</span>
      </span>
    </v-tab>
    <v-tab href="#tab-2">
      <span>
        <span>周报</span>
      </span>
    </v-tab>
    <v-tab href="#tab-3">
      <span>
        <span>月报</span>
      </span>
    </v-tab>
  </v-tabs>
  <v-tabs-items v-model="tab">
    <v-tab-item id="tab-1">
      <sub-daily-reports @displayRightSummaryEdit="openRightSummaryEditor"></sub-daily-reports>
    </v-tab-item>
    <v-tab-item id="tab-2">
      <sub-weekly-reports @displayRightSummaryEdit="openRightSummaryEditor"></sub-weekly-reports>
    </v-tab-item>
    <v-tab-item id="tab-3">
      <sub-monthly-reports @displayRightSummaryEdit="openRightSummaryEditor"></sub-monthly-reports>
    </v-tab-item>
  </v-tabs-items>
</v-container>
</template>

<script>
export default {
  name: 'SubSummaryTab',
  components: {
    SubDailyReports: () => import('@/components/summary/SubDailyReports.vue'),
    SubWeeklyReports: () => import('@/components/summary/SubWeeklyReports.vue'),
    SubMonthlyReports: () => import('@/components/summary/SubMonthlyReports.vue'),
  },
  data: () => ({
    tab: null
  }),
  methods: {
    openRightSummaryEditor(e) {
      this.$emit('displayRightSummaryEdit', e);
    }    
  }
}
</script>
